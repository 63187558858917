
















import Vue from 'vue'
import { TheMask, mask } from 'vue-the-mask'

export default Vue.extend({
  name: 'InputMask',

  components: {
    TheMask
  },

  directives: {
    mask
  },

  props: {
    id: {
      type: String,
      default: () => ''
    },

    required: {
      type: Boolean,
      default: () => false
    },

    readonly: {
      type: Boolean,
      default: () => false
    },

    value: {
      type: String,
      default: () => ''
    },

    mask: {
      type: String,
      default: () => ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'tel'
    },

    state: {
      type: Boolean,
      default: () => true
    }
  },

  data () {
    return {
      tokens: {
        F: {
          pattern: /(?!8)\d/
        },
        '#': {
          pattern: /\d/
        }
      }
    }
  },

  methods: {
    input (val: any) {
      if (this.value !== val) {
        if (this.mask === '+7 (F##) ###-##-##') {
          this.$emit('input', `7${val}`)
        } else {
          this.$emit('input', val)
        }
      }
    },
    handleChange (val: string) {
      this.$emit('change', val)
    }
  }
})
