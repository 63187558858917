
























































































































































































































import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import Loading from '@/components/Loading/index.vue'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import ValidatedBFormMaskedInput from '@/components/ui/ValidatedBFormMaskedInput.vue'
import { ProfileRequisites } from '@/store/modules/profiles/types'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'ProfileRequisites',

  components: {
    Loading,
    ValidatedBFormInput,
    ValidatedBFormMaskedInput
  },
  props: {
    requisites: {
      type: Object as PropType<object>,
      default: () => ({})
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data () {
    return {
      saving: false,
      localRequisites: {} as ProfileRequisites
    }
  },

  computed: {
    ...mapState('common', [
      'businessCategories'
    ])
  },

  created () {
    this.localRequisites = cloneDeep(this.requisites)
  },

  methods: {
    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      const payload = Object.fromEntries(Object.entries(this.localRequisites).filter(([key, value] : [ key: string, value: string ]) => value.length))

      this.$emit('onSubmit', payload)
    }
  }
})
